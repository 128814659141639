import axios from "axios";
import { ref, inject, onMounted, getCurrentInstance } from "vue";

const GLOBAL_SERVICE_DATA_SYMBOL = Symbol();
const CACHE_DURATION = 60 * 60 * 1000;

const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
};

const analyticsPlugin = {
    install(app) {
        const globalServiceData = ref(null);

        app.provide(GLOBAL_SERVICE_DATA_SYMBOL, globalServiceData);

        const getGeoData = async () => {
            const cachedData = localStorage.getItem("geoData");
            const cachedTime = localStorage.getItem("geoDataTime");

            if (cachedData && cachedTime) {
                const now = new Date().getTime();
                if (now - parseInt(cachedTime) < CACHE_DURATION) {
                    return JSON.parse(cachedData);
                }
            }

            try {
                const response = await axios.get(
                    "https://api.ipify.org?format=json",
                    { timeout: 5000 },
                );
                const geoData = {
                    IPv4: response.data.ip,
                    country: null,
                    city: null,
                };

                localStorage.setItem("geoData", JSON.stringify(geoData));
                localStorage.setItem(
                    "geoDataTime",
                    new Date().getTime().toString(),
                );

                return geoData;
            } catch (error) {
                console.error("Failed to get geolocation data:", error);

                return { IPv4: null, country: null, city: null };
            }
        };

        const getCurrentPosition = () => {
            return new Promise((resolve) => {
                if (!navigator.geolocation) {
                    resolve({ latitude: null, longitude: null });
                } else {
                    navigator.geolocation.getCurrentPosition(
                        (position) =>
                            resolve({
                                latitude: position.coords.latitude,
                                longitude: position.coords.longitude,
                            }),
                        () => resolve({ latitude: null, longitude: null }),
                        { timeout: 5000, maximumAge: 0 },
                    );
                }
            });
        };

        const getIdFromUrl = (url) => {
            const searchParams = new URLSearchParams(url.split("?")[1]);
            return (
                searchParams.get("id") ||
                searchParams.get("center_product_id") ||
                null
            );
        };
        const getUserAccount = () => {
            const id = localStorage.getItem("id");
            return id || "Guest";
        };

        const logClickEvent = async (event, componentData = {}) => {
            const target = event.target;
            const path = window.location.pathname;
            const idFromUrl = getIdFromUrl(window.location.search);

            const getMeaningfulText = (element) => {
                if (element.textContent.trim())
                    return element.textContent.trim();
                if (element.value) return element.value;
                if (element.alt) return element.alt;
                if (element.title) return element.title;
                return null;
            };

            let defaultServiceName = getMeaningfulText(target);
            if (!defaultServiceName && target.closest("button")) {
                defaultServiceName = getMeaningfulText(
                    target.closest("button"),
                );
            }
            if (!defaultServiceName && target.closest("a")) {
                defaultServiceName = getMeaningfulText(target.closest("a"));
            }

            const geoData = await getGeoData();
            const position = await getCurrentPosition();

            const logData = {
                category: `RCBT-SIP`,
                name: `${path.split("/").pop() || "home"}_Click`,
                customValue: {
                    serviceId:
                        idFromUrl ||
                        componentData.serviceId ||
                        globalServiceData.value?.serviceId ||
                        null,
                    serviceName:
                        document.getElementById("ServiceName")?.textContent ||
                        componentData.serviceName ||
                        globalServiceData.value?.serviceName ||
                        defaultServiceName ||
                        "null",
                    serviceType: target.tagName.toLowerCase(),
                    serviceIP: geoData.IPv4,
                    location: {
                        lat: position.latitude,
                        lon: position.longitude,
                    },
                    account: getUserAccount(),
                    keyword: null,
                    datetime: new Date().toISOString(),
                },
            };

            console.info(
                "Sending click data to API:",
                JSON.stringify(logData, null, 2),
            );

            try {
                const ES_URL =
                    process.env.ES_URL || "https://elasticapi.kims-rmuti.com";
                const API_KEY =
                    process.env.API_KEY ||
                    "ApiKey cWlFc0RaQUJHOGRmaTdiLTA2cTU6V2I0VVFwaHJSRUtoOHI0TWZmNlBKZw==";

                const response = await axios.post(
                    `${ES_URL}/peechang-log.web/_doc`,
                    logData,
                    {
                        headers: {
                            Authorization: API_KEY,
                            "Content-Type": "application/json",
                        },
                    },
                );
                console.info(
                    "API Response:",
                    JSON.stringify(response.data, null, 2),
                );
                return response.data;
            } catch (error) {
                console.error("Failed to log click event:", error);
                if (error.response) {
                    console.error("Error response:", error.response.data);
                }
            }
        };

        const debouncedLogClickEvent = debounce(logClickEvent, 300);

        app.config.globalProperties.$logClickEvent = debouncedLogClickEvent;
        app.config.globalProperties.getGeoData = getGeoData;
        app.config.globalProperties.getCurrentPosition = getCurrentPosition;
        app.config.globalProperties.getUserAccount = getUserAccount;

        app.mixin({
            mounted() {
                document.addEventListener("click", this.handleGlobalClick);
                if (this.$router) {
                    this.$router.afterEach((to) => {
                        const idFromUrl = getIdFromUrl(to.fullPath);
                        debouncedLogClickEvent(
                            {
                                target: {
                                    tagName: "ROUTE_CHANGE",
                                    textContent: to.name || to.path,
                                },
                            },
                            {
                                serviceId: idFromUrl,
                                ...globalServiceData.value,
                            },
                        );
                    });
                }
            },
            methods: {
                handleGlobalClick(event) {
                    const idFromUrl = getIdFromUrl(window.location.search);
                    debouncedLogClickEvent(event, {
                        serviceId: idFromUrl,
                        ...globalServiceData.value,
                    });
                },
            },
            beforeUnmount() {
                document.removeEventListener("click", this.handleGlobalClick);
            },
        });
    },
};

export function useAnalytics() {
    const globalServiceData = inject(GLOBAL_SERVICE_DATA_SYMBOL);
    const instance = getCurrentInstance();

    const setGlobalServiceData = (data) => {
        globalServiceData.value = data;
    };

    const getServiceData = () => {
        return (
            globalServiceData.value || { serviceId: null, serviceName: null }
        );
    };

    const logEvent = (event, eventType) => {
        if (instance && instance.proxy && instance.proxy.$logClickEvent) {
            instance.proxy.$logClickEvent(
                {
                    target: {
                        tagName: eventType,
                        textContent: event.textContent || document.title,
                    },
                },
                getServiceData(),
            );
        } else {
            console.error("$logClickEvent is not available");
        }
    };
    const getUserAccount = () => {
        const id = localStorage.getItem("id");
        return id || "Guest";
    };

    onMounted(() => {
        logEvent({ textContent: document.title }, "PAGE_VIEW");
    });

    return {
        setGlobalServiceData,
        getServiceData,
        logEvent,
        getUserAccount,
    };
}

export default analyticsPlugin;
